import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Carousel,
  Button,
} from "react-bootstrap";
import withRouter from "./helpers/withrouter";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getShopDetails } from "../ApiMethods/Shop";
import APP_CONSTANTS from "../constants/AppConstants";
import shopDummyLogo from "../resources/images/dummy_shop_logo.svg";
import { cloneDeep } from "lodash";
import $ from "jquery";
import "../styles/pages/guest.scss";
import { SwalPopup } from "./SwalPopup/SwalPopup";
import SWAL_CONST from "./SwalPopup/SwalConstants";
import { sendOtpToUser, validateUserOtp } from "../ApiMethods/User";
import { setUserInfoState } from "../Reux/Actions/UserInfoAction";
import { getUserInfoState } from "../Reux/Selectors/UserInfoSelectors";
import { scrollToElement } from "../Utilities/util";
import loginIcon from "../resources/images/login.svg";
class Guest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentGuestView: APP_CONSTANTS.GUEST_CONSTANTS.VIEW.VALIDATE_OTP,
      shopDetails: null,
      value: "",
      service: "",
      validateOtpFormValidated: false,
      queueFormValidated: false,
      userFormInfo: {
        otp: "",
        phoneNo: "",
      },
    };
  }
  componentDidMount() {
    // check if userLogged in then set view state
    this.setInitialGuestViewBasedOnLoggedInfo();
    if (this.props.params?.id != null) {
      this.getShopData();
    }
  }

  setInitialGuestViewBasedOnLoggedInfo = () => {
    const { userLoggedInInfo } = this.props;

    if (userLoggedInInfo) {
      this.setState({
        currentGuestView: APP_CONSTANTS.GUEST_CONSTANTS.VIEW.LOGGED_IN,
      });
    }
  };

  render() {
    return (
      <div className="guest-container d-flex flex-column h-100">
        <div style={{ backgroundColor: "" }} className="guest-header mt-2">
          <Container>
            <h1>Saloon App</h1>
            <p>Login to check current status</p>
            <p>
              Note that once your turn is served/rejected, your data will be
              erased.
            </p>
            <p>
              Please download the app and register to retain your data
              (history,orders etc), get notified when your turn arrives, find
              more features on the app.
            </p>
          </Container>
        </div>
        <div className="guest-scrollable-container">
          {this.guestBodyRenderer()}
        </div>
      </div>
    );
  }
  guestBodyRenderer = () => {
    const { currentGuestView } = this.state;
    // render view based on current state
    switch (currentGuestView) {
      case APP_CONSTANTS.GUEST_CONSTANTS.VIEW.VALIDATE_OTP:
      default:
        return this.renderValidateOtpSection();

      case APP_CONSTANTS.GUEST_CONSTANTS.VIEW.SEND_OTP:
        return this.renderGuestOtpRegForm();

      case APP_CONSTANTS.GUEST_CONSTANTS.VIEW.LOGGED_IN:
        return this.showLogged();
    }
  };

  getShopData = async () => {
    const { showLoader, params } = this.props;
    showLoader(true);
    const successCallback = (response) => {
      this.setState({
        currentGuestView: APP_CONSTANTS.GUEST_CONSTANTS.VIEW.SEND_OTP,
        shopDetails: response.data.data,
      });
    };
    await getShopDetails(params.id, successCallback);
    showLoader(false);
  };

  //post login
  showLogged = () => {
    let code = (
      <Container className="mt-3 text-center">
        <Row>
          <Col>
            <Card className="shadow-lg p-3">
              <h1>Saloon test</h1>
              <hr />
              <Card.Text>
                Queue Description
                <br />
                Your turn will arrive in 20 minutes
                <br />
                Please download the app and register to retain your data.
              </Card.Text>
            </Card>
          </Col>
        </Row>
      </Container>
    );
    return code;
  };

  renderShopDetailsGallery = () => {
    const { shopDetails } = this.state;

    let itemsArray = [];

    itemsArray.push(shopDetails.shopGalleryImg1);

    if (shopDetails.shopGalleryImg2) {
      itemsArray.push(shopDetails.shopGalleryImg1);
    }

    return (
      <Carousel className="mt-2" variant="dark">
        {itemsArray.map((carouselItem, carouselIndex) => {
          return (
            <Carousel.Item key={carouselIndex + "shopGallery"}>
              <img
                className="d-block w-100"
                height={300}
                src={shopDummyLogo}
                alt={"gallery image" + carouselIndex}
              />
            </Carousel.Item>
          );
        })}
      </Carousel>
    );
  };

  scrollToForm = () => {
    scrollToElement(".guest-scrollable-container", ".send-otp-form");
  };

  //register in queue
  renderGuestOtpRegForm = () => {
    const { queueFormValidated, shopDetails, userFormInfo } = this.state;

    // let shopLogo = shopDetails?.shopLogo
    //   ? shopDetails?.shopLogo
    //   : shopDummyLogo;
    // override shop logo to load dummy temporarily

    let shopLogo = shopDummyLogo;

    return (
      <Container className="mt-3 text-center">
        <Row>
          <Col>
            <Card className="shadow-lg p-3">
              {shopDetails ? (
                <>
                  <div className="d-flex justify-content-center mb-3">
                    <Button
                      className="w-max-content custom-orange-button-rounded m-0"
                      onClick={this.scrollToForm}
                    >
                      Get in Queue
                    </Button>
                  </div>
                  <div className="shop-card-header-section d-flex justify-content-around">
                    <Row className="w-100">
                      <Col className="d-flex flex-wrap w-100 justify-content-md-start justify-content-center">
                        <div className="shop-logo">
                          <img
                            src={shopLogo}
                            width={100}
                            height={100}
                            alt="shop-logo"
                          />
                        </div>
                        <div className="text-center my-auto m-3">
                          <h1>{shopDetails.shopName}</h1>
                          <div>{shopDetails.shopDescription}</div>
                        </div>
                      </Col>
                      <Col className="my-md-auto mt-3">
                        <b>Location:</b> {shopDetails.shopAddress}
                      </Col>
                    </Row>
                  </div>
                  <hr />
                  <Card.Body>
                    <b>{shopDetails.shopName} Gallery</b>
                    {shopDetails.shopGalleryImg1
                      ? this.renderShopDetailsGallery()
                      : APP_CONSTANTS.TEXT.NO_IMAGES_TO_BE_DISPLAYED}
                  </Card.Body>
                </>
              ) : (
                APP_CONSTANTS.TEXT.NO_DATA_FOUND
              )}
            </Card>
            {shopDetails ? (
              <Card className="mt-2 p-2 shadow-lg">
                <Card.Title>Registration Form</Card.Title>
                <hr />
                <Form
                  className="send-otp-form"
                  noValidate
                  validated={queueFormValidated}
                  onSubmit={this.submitSendOtp}
                >
                  <Row>
                    <Form.Group as={Col} sm={6} controlId="firstName">
                      <Form.Label>
                        Enter Name: <span className="important">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="firstName"
                        onChange={this.handleValueChange}
                        value={userFormInfo.firstName}
                        placeholder="Enter Name"
                        required
                      />
                    </Form.Group>
                    <Form.Group as={Col} sm={6} controlId="lastName">
                      <Form.Label>
                        Enter Surname: <span className="important">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="lastName"
                        onChange={this.handleValueChange}
                        value={userFormInfo.lastName}
                        placeholder="Enter Surname"
                        required
                      />
                    </Form.Group>
                  </Row>
                  <Row className="mt-2">
                    <Form.Group as={Col} sm={6} controlId="phoneNo">
                      <Form.Label>
                        Enter phone number: <span className="important">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="phoneNo"
                        onChange={(e) => {
                          if (
                            e.target.value.match(
                              APP_CONSTANTS.HELPER_VALUES.NUMERIC_REGEX
                            ) != null
                          ) {
                            this.handleValueChange(e);
                          }
                        }}
                        maxLength={10}
                        minLength={10}
                        value={userFormInfo.phoneNo}
                        placeholder="Enter Number"
                        required
                      />
                    </Form.Group>
                    <Form.Group as={Col} sm={6} controlId="service">
                      <Form.Label>Select Service:</Form.Label>
                      <Form.Control
                        as="select"
                        name="service"
                        value={userFormInfo.service}
                        onChange={this.handleServiceChange}
                      >
                        {shopDetails.services?.map(
                          (shopService, shopServiceIndex) => {
                            return (
                              <option
                                value={shopService.toString()}
                                key={shopServiceIndex + "shopServices"}
                              >
                                {shopService.serviceName}
                              </option>
                            );
                          }
                        )}
                      </Form.Control>
                    </Form.Group>
                  </Row>
                  <Row className="mt-2">
                    <Col>
                      <input
                        className="btn btn-primary"
                        type="submit"
                        value="Submit"
                      />
                    </Col>
                  </Row>
                </Form>
              </Card>
            ) : null}
          </Col>
        </Row>
      </Container>
    );
  };

  validateOtpApiCall = async () => {
    const { userFormInfo } = this.state;
    const { showLoader, setUserInfoStateAction } = this.props;
    showLoader(true);
    let reqBody = {
      phoneNo: userFormInfo.phoneNo,
      password: userFormInfo.otp,
    };
    await validateUserOtp(reqBody, (response) => {
      try {
        setUserInfoStateAction(response.data.data);
        this.setState({
          currentGuestView: APP_CONSTANTS.GUEST_CONSTANTS.VIEW.LOGGED_IN,
        });
        let swalConfig = {
          confirmButtonText: SWAL_CONST.SWAL_BUTTONS.OK,
          description: "Logged in successfully",
        };
        SwalPopup(swalConfig);
      } catch (e) {
        console.log(e);
      }
    });
    showLoader(false);
  };

  sendOtpApiCall = async () => {
    const { userFormInfo } = this.state;
    const { showLoader } = this.props;
    showLoader(true);
    let reqBody = {
      phoneNo: userFormInfo.phoneNo,
      fullName: userFormInfo.firstName + " " + userFormInfo.lastName,
      verified: false,
      role: "user",
      loginVia: "web",
    };

    await sendOtpToUser(reqBody, () => {
      this.setState({
        currentGuestView: APP_CONSTANTS.GUEST_CONSTANTS.VIEW.VALIDATE_OTP,
      });
      let swalConfig = {
        confirmButtonText: SWAL_CONST.SWAL_BUTTONS.OK,
        description: "OTP sent successfully.",
      };
      SwalPopup(swalConfig);
    });
    showLoader(false);
  };

  handleFormError = () => {
    var errorElements = document.querySelectorAll("input.form-control:invalid");
    scrollToElement(".guest-scrollable-container", errorElements[0]);

    let swalConfig = {
      title: SWAL_CONST.SWAL_ICONS.WARNING.VALUE,
      icon: SWAL_CONST.SWAL_ICONS.WARNING.ENUM,
      confirmButtonText: SWAL_CONST.SWAL_BUTTONS.OK,
      description: "Please fix the errors in your form",
    };

    SwalPopup(swalConfig);
  };

  submitValidateOtp = async (event) => {
    const form = event.currentTarget;
    console.log("form: ", form);
    if (form.checkValidity()) {
      event.preventDefault();
      await this.validateOtpApiCall();
    } else {
      event.preventDefault();
      event.stopPropagation();

      this.handleFormError();
    }

    this.setState({
      validateOtpFormValidated: true,
    });
  };

  submitSendOtp = async (event) => {
    const form = event.currentTarget;
    if (form.checkValidity()) {
      event.preventDefault();
      await this.sendOtpApiCall();
    } else {
      event.preventDefault();
      event.stopPropagation();

      this.handleFormError();
    }

    this.setState({
      queueFormValidated: true,
    });
  };

  handleValueChange = (e) => {
    const { userFormInfo } = this.state;

    let modifiedUserFormInfo = cloneDeep(userFormInfo);

    modifiedUserFormInfo[e.target.name] = e.target.value;

    this.setState({
      userFormInfo: modifiedUserFormInfo,
    });
  };

  //login to check details
  renderValidateOtpSection = () => {
    const { validateOtpFormValidated, userFormInfo } = this.state;
    return (
      <Container className="mt-3 text-center">
        <Row>
          <Col>
            <Card className="mt-2 p-2 shadow-lg">
              <Card.Img
                variant="top"
                src={loginIcon}
                width={200}
                height={200}
              ></Card.Img>
              <Card.Title className="mt-3">
                Enter OTP to check queue status
              </Card.Title>
              {/* <hr /> */}
              <Form
                onSubmit={this.submitValidateOtp}
                noValidate
                validated={validateOtpFormValidated}
              >
                <Row>
                  <Form.Group as={Col} sm={6} controlId="phoneNo">
                    <Form.Label>
                      Phone No: <span className="important">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="phoneNo"
                      onChange={(e) => {
                        if (
                          e.target.value.match(
                            APP_CONSTANTS.HELPER_VALUES.NUMERIC_REGEX
                          ) != null
                        ) {
                          this.handleValueChange(e);
                        }
                      }}
                      value={userFormInfo.phoneNo}
                      placeholder="Enter Phone Number"
                      minLength={10}
                      maxLength={10}
                      required
                    />
                  </Form.Group>

                  <Form.Group as={Col} sm={6} controlId="otp">
                    <Form.Label>
                      Enter OTP: <span className="important">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="otp"
                      onChange={this.handleValueChange}
                      value={userFormInfo.otp}
                      placeholder="Enter OTP"
                      required
                    />
                  </Form.Group>
                </Row>
                <Row className="mt-2">
                  <Col>
                    <input
                      className="btn btn-primary"
                      type="submit"
                      value="Submit"
                    />
                  </Col>
                </Row>
              </Form>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  };
  handleChange = (event) => {
    this.setState({ value: event.target.value });
    // this.setState({ form: { no: event.target.value } });
  };
  handleServiceChange = (event) => {
    this.setState({ service: event.target.value });
    // this.setState({ form: { no: event.target.value } });
  };

  handleSubmit = (event) => {
    // alert("A name was submitted: " + this.state.form.no);
    alert("Number: " + this.state.value + " Service: " + this.state.service);
    event.preventDefault();
  };
  handleSub = (event) => {
    this.setState({ res: "login" });
    event.preventDefault();
  };
}

const mapStateToProps = (state) => ({
  userLoggedInInfo: getUserInfoState(state),
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      setUserInfoStateAction: setUserInfoState,
    },
    dispatch
  );
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Guest));
