import React, { Component } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
class Body extends Component {
  state = {
    cardData: [
      {
        id: 1,
        image: "/assets/images/1.jpg",
        title: "Android",
        text: "",
        link: "https://sastabuy.in",
      },
      {
        id: 2,
        image: "/assets/images/2.jpg",
        title: "IOS",
        text: "Under Development",
        link: "",
      },
    ],
  };

  render() {
    return (
      <React.Fragment>
        <div style={{ backgroundColor: "" }} className="mt-2">
          <Container>
            <h1>Saloon App</h1>
            <p>
              Please scan the QR code below to download the app, or click on the
              links provided.
            </p>
          </Container>
        </div>

        <Container>
          <Row className="text-center">{this.cards()}</Row>
        </Container>
      </React.Fragment>
    );
  }

  cards = () => {
    const cardList = this.state.cardData;
    const cardListMain = cardList.map((c) => (
      <Col key={c.id} xm={12} sm={6}>
        <Card className="shadow-lg p-5 h-100">
          <Card.Title>{c.title}</Card.Title>
          <Card.Img variant="top" src={c.image} />
          <Card.Body>
            {c.text !== "" ? <Card.Text>{c.text}</Card.Text> : <div></div>}
            {c.link !== "" ? (
              <Card.Link target="_blank" href={c.link}>
                Download
              </Card.Link>
            ) : (
              <div></div>
            )}
          </Card.Body>
        </Card>
      </Col>
    ));
    return cardListMain;
  };
}

export default Body;
