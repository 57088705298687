import AXIOS_CONST from "../components/helpers/Axios/AiosConstants";
import { callApi } from "../components/helpers/Axios/AxiosTemplate";
import { VALIDATE_OTP, SEND_OTP } from "../constants/ApiUrls";

export const validateUserOtp = async (userInfoObj, successCallback) => {
  let url = VALIDATE_OTP;
  console.log("url: ", url);

  let apiConfig = {
    method: AXIOS_CONST.REQUEST_METHOD.POST,
    url: url,
    data: userInfoObj,
  };

  await callApi(apiConfig, successCallback);
};

export const sendOtpToUser = async (userInfoObj, successCallback) => {
  let url = SEND_OTP;

  let apiConfig = {
    method: AXIOS_CONST.REQUEST_METHOD.POST,
    url: url,
    data: userInfoObj,
  };

  await callApi(apiConfig, successCallback);
};
