let BASE_API_URL = process.env.REACT_APP_HOSTNAME;

// only append /api to actual enviornment
if (!BASE_API_URL.includes("localhost")) {
  BASE_API_URL += "/api";
}

export const SHOP_URL = BASE_API_URL + "/shops/";
export const USERS_URL = BASE_API_URL + "/users/";

// url for user actions
export const VALIDATE_OTP = USERS_URL + "validate-otp/";
export const SEND_OTP = USERS_URL + "send-otp/";
