import AXIOS_CONST from "../components/helpers/Axios/AiosConstants";
import { callApi } from "../components/helpers/Axios/AxiosTemplate";
import { SHOP_URL } from "../constants/ApiUrls";

export const getShopDetails = async (shopId, successCallback) => {
  let url = SHOP_URL + shopId;

  let apiConfig = {
    method: AXIOS_CONST.REQUEST_METHOD.GET,
    url: url,
  };

  await callApi(apiConfig, successCallback);
};
