const APP_CONSTANTS = {
  ROUTES: {
    HOME: { NAME: "Home", PATH: "/", NAVBAR_VISIBILITY: true },
    GUEST_LOGIN: { NAME: "Guest", PATH: "/guest", NAVBAR_VISIBILITY: true },
    GUEST_SHOP: { NAME: "Shop", PATH: "/guest/:id", NAVBAR_VISIBILITY: false },
    ABOUT: { NAME: "About", PATH: "/about", NAVBAR_VISIBILITY: true },
  },
  TEXT: {
    NO_DATA_FOUND: "No data found.",
    NO_IMAGES_TO_BE_DISPLAYED: "No images to be displayed",
  },
  GUEST_CONSTANTS: {
    VIEW: {
      VALIDATE_OTP: 1,
      SEND_OTP: 2,
      LOGGED_IN: 3,
    },
  },
  HELPER_VALUES: {
    NUMERIC_REGEX: "^[0-9]*$",
  },
};

export default APP_CONSTANTS;
