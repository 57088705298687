const SWAL_CONST = {
    SWAL_ICONS: {
        SUCCESS: {
            ENUM: "success",
            VALUE: "Success"
        },
        ERROR: {
            ENUM: "error",
            VALUE: "Error"
        },
        WARNING: {
            ENUM: "warning",
            VALUE: "Warning"
        },
        INFO: {
            ENUM: "info",
            VALUE: "Info"
        },
        Question: {
            ENUM: "question",
            VALUE: "Question"
        },
    },
    SWAL_BUTTONS: {
        OK: "Ok"
    }
}

export default SWAL_CONST