import React, { Component } from "react";
import logo from "../resources/images/q.svg";
import { Link, Outlet } from "react-router-dom";
import { Navbar, Nav } from "react-bootstrap";
import "../styles/pages/header.scss";
import APP_CONSTANTS from "../constants/AppConstants";
class Header extends Component {
  navItems = () => {
    return Object.entries(APP_CONSTANTS.ROUTES)
      .filter((routeEntry) => routeEntry[1].NAVBAR_VISIBILITY)
      .map((routeEntry, routeEntryIndex) => {
        let route = routeEntry[1];
        return (
          <Link
            className=" p-3"
            to={route.PATH}
            key={routeEntryIndex + "routes"}
          >
            {route.NAME}
          </Link>
        );
      });
  };

  render() {
    return (
      <Navbar
        sticky="top"
        bg="dark"
        variant="dark"
        className="header shadow"
        expand="lg"
      >
        <Navbar.Brand style={{ width: 50, marginLeft: "1rem" }} href="/">
          {/* <Image src="/assets/images/react.png" thumbnail /> */}
          <div className="ml-2">
            <img src={logo} alt="logo" />
          </div>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse className="justify-content-end" id="basic-navbar-nav">
          <Nav className=" mr-5">{this.navItems()}</Nav>
        </Navbar.Collapse>
        <Outlet />
      </Navbar>
    );
  }
}

export default Header;
