import Swal from "sweetalert2";
import SWAL_CONST from "./SwalConstants";

export const SwalPopup = (swalConfig) => {
  console.log("swalConfig: ", swalConfig);
  Swal.fire({
    title: swalConfig.title
      ? swalConfig.title
      : SWAL_CONST.SWAL_ICONS.SUCCESS.VALUE,
    text: swalConfig.description,
    icon: swalConfig.icon
      ? swalConfig.icon
      : SWAL_CONST.SWAL_ICONS.SUCCESS.ENUM,
    showConfirmButton: swalConfig.confirmButtonText,
    showCancelButton: swalConfig.cancelButtonText,
    confirmButtonText: swalConfig.confirmButtonText,
    cancelButtonText: swalConfig.cancelButtonText,
    confirmButtonColor: "#4C4762",
    cancelButtonColor: "#9693a3",
  }).then((result) => {
    if (result.isConfirmed) {
      // if confirm handle method passed call that method
      if (swalConfig.confirmButtonHandle) swalConfig.confirmButtonHandle();
    } else if (
      /* Read more about handling dismissals below */
      result.dismiss === Swal.DismissReason.cancel
    ) {
      if (swalConfig.cancelButtonHandle) swalConfig.cancelButtonHandle();
    }
  });
};
