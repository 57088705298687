import REDUX_CONSTANTS from "../ReduxConstants";

export const setUserInfoState = (modifiedUserInfo) => {
  return function (dispatch) {
    dispatch({
      type: REDUX_CONSTANTS.USER_INFO,
      userInfo: modifiedUserInfo,
    });
  };
};
