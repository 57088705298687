import REDUX_CONSTANTS from "../ReduxConstants";

let initialReducerState = null;
const userInfoReducer = (state = initialReducerState, action) => {
  switch (action.type) {
    case REDUX_CONSTANTS.USER_INFO:
      return {
        userInfo: action.userInfo,
      };

    default:
      return state;
  }
};

export default userInfoReducer;
