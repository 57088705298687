import $ from "jquery";
export const scrollToElement = (elementToBeScrolled, elementTarget) => {
  var elementTop = $(elementTarget).offset().top;
  var divTop = $(elementToBeScrolled).offset().top;
  var elementRelativeTop = elementTop - divTop;
  $(elementToBeScrolled).animate(
    {
      scrollTop: elementRelativeTop,
    },
    1000
  );
};
