import "bootstrap/dist/css/bootstrap.min.css";
import React, { Component } from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import { Row, Col, Card, Container } from "react-bootstrap";
import Header from "./header";
import Body from "./body";
import Footer from "./footer";
import About from "./about";
import Guest from "./guest";
import Loader from "./Loader";
import APP_CONSTANTS from "../constants/AppConstants";
class Home extends Component {
  state = {
    loader: false,
  };
  showLoader = (value) => {
    this.setState({
      loader: value,
    });
  };
  render() {
    const { loader } = this.state;
    const Page404 = ({ location }) => (
      <Container className="mt-5 text-center">
        <Row>
          <Col>
            <Card className="shadow-lg p-3">
              <h1>Page not found</h1>
            </Card>
          </Col>
        </Row>
      </Container>
    );
    return (
      <BrowserRouter>
        <div className="position-relative h-100 d-flex flex-column">
          {loader && <Loader />}
          <Header />
          <div className="body-container">
            <Routes>
              <Route
                index
                path={APP_CONSTANTS.ROUTES.HOME.PATH}
                element={<Body />}
              />
              <Route
                path={APP_CONSTANTS.ROUTES.GUEST_LOGIN.PATH}
                element={<Guest showLoader={this.showLoader} />}
              />
              <Route
                path={APP_CONSTANTS.ROUTES.GUEST_SHOP.PATH}
                element={<Guest showLoader={this.showLoader} />}
              />
              <Route
                path={APP_CONSTANTS.ROUTES.ABOUT.PATH}
                element={<About />}
              />
              <Route component={<Page404 />} />
            </Routes>
          </div>
          <Footer />
        </div>
      </BrowserRouter>
    );
  }
}

export default Home;
