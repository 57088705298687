import APP_CONSTANTS from "../../../constants/AppConstants";
import SWAL_CONST from "../../SwalPopup/SwalConstants";
import { SwalPopup } from "../../SwalPopup/SwalPopup";
import AXIOS_CONST from "./AiosConstants";
import axios from "axios";
import store from "../../../Reux/Store";
import { setUserInfoState } from "../../../Reux/Actions/UserInfoAction";

export const callApi = async (apiConfig, successCallback) => {
  apiConfig.headers = { authorization: process.env.REACT_APP_AUTH };
  try {
    const response = await axios(apiConfig);
    if (response.status === AXIOS_CONST.RESPONSE_STATUSES.SUCCESS) {
      // check if message is success only then call the callback method
      if (response.data.message === AXIOS_CONST.RESPONSE_MESSAGES.SUCCESS) {
        successCallback(response);
      } else if (
        response.data.message === AXIOS_CONST.RESPONSE_MESSAGES.FALIURE
      ) {
        // check if error obj passed
        if (response.data.data.error) {
          handleAxiosError(response.data.data.error.CODE);
        }
        // else send null to error obj
        else {
          handleAxiosError(null);
        }
      }
    } else {
      handleAxiosError(response.status);
    }
  } catch (e) {
    handleAxiosError(null);
  }
};

export const redirectToLoginPage = () => {
  window.location.href = window.origin + APP_CONSTANTS.ROUTES.GUEST_LOGIN.PATH;
  // set user login info as null
  store.dispatch(setUserInfoState(null));
};

export const handleAxiosError = (errorType) => {
  let swalConfig = {
    title: SWAL_CONST.SWAL_ICONS.ERROR.VALUE,
    icon: SWAL_CONST.SWAL_ICONS.ERROR.ENUM,
    confirmButtonText: SWAL_CONST.SWAL_BUTTONS.OK,
  };

  // switch in case of default errors
  switch (errorType) {
    case AXIOS_CONST.RESPONSE_STATUSES.UNAUTHORIZED:
      swalConfig.description = AXIOS_CONST.MESSAGES.UNAUTHORIZED;
      swalConfig.confirmButtonHandle = () => {
        // redirect to login page
        redirectToLoginPage();
      };
      break;

    case AXIOS_CONST.CUSTOM_ERROR.OTP_EXPIRED.CODE:
    case AXIOS_CONST.CUSTOM_ERROR.RE_ENTER_OTP.CODE:
      swalConfig.confirmButtonHandle = () => {
        // redirect to login page
        redirectToLoginPage();
      };
      break;

    case AXIOS_CONST.RESPONSE_STATUSES.INTERNAL_ERROR:
    default:
      swalConfig.description = AXIOS_CONST.MESSAGES.INTERNAL_SERVER_ERROR;
      break;
  }

  // if errorType passed is of custom error then override the error
  let customErrorEntry = Object.entries(AXIOS_CONST.CUSTOM_ERROR).find(
    (entry) => entry[1].CODE === errorType
  );
  if (customErrorEntry) {
    swalConfig.description = customErrorEntry[1].MESSAGE;
  }

  SwalPopup(swalConfig);
};
