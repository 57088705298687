const AXIOS_CONST = {
  REQUEST_METHOD: {
    GET: "get",
    PUT: "put",
    POST: "post",
  },
  RESPONSE_STATUSES: {
    SUCCESS: 200,
    INTERNAL_ERROR: 500,
    UNAUTHORIZED: 401,
  },
  CUSTOM_ERROR: {
    EXISTING_USER: {
      CODE: 1001,
      MESSAGE: "User already registered, please sign in using saloon app",
    },
    OTP_EXPIRED: {
      CODE: 1002,
      MESSAGE: "OTP Expired please register again",
    },
    RE_ENTER_OTP: {
      CODE: 1003,
      MESSAGE:
        "You have already generated otp for this number, please enter your otp to check details again",
    },
    INCORRECT_PASSWORD: {
      CODE: 1004,
      MESSAGE:
        "The entered password is not correct. Please try again entring a correct password",
    },
  },
  RESPONSE_MESSAGES: {
    SUCCESS: "success",
    FALIURE: "faliure",
  },
  MESSAGES: {
    INTERNAL_SERVER_ERROR: "Internal Server Error!",
    UNAUTHORIZED: "Unauthorized Request.",
  },
};

export default AXIOS_CONST;
