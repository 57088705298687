import { Spinner } from "react-bootstrap";
import "../styles/pages/Loader.scss";
const Loader = (props) => {
  return (
    <div className="loaderContainer">
      <div className="loaderInner">
        <Spinner className="loader" animation="border" role="status">
          <span className="sr-only"></span>
        </Spinner>
      </div>
    </div>
  );
};

export default Loader;
