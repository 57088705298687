import React, { Component } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import saloonIcon from "../resources/images/q.svg"
class About extends Component {
  state = { info: "about", data: [{ info: "About Saloon App" }] };
  render() {
    return (
      <React.Fragment>
        <Container className="mt-3 text-center">
          <Row>
            <Col>
              <Card className="shadow-lg p-3">
                <h1>Saloon App</h1>
                {/* <Card.Title>Saloon App</Card.Title> */}
                <hr />
                <Card.Body>
                  <Row>
                    <Col>
                      Tired of waiting in a line at the saloon?
                      <br />
                      Stay home, get in queue and get notified when your its
                      your turn
                    </Col>
                  </Row>
                  <Row className="mt-2">
                    <Col>
                    <img height={300} width={300} alt="saloon icon" src={saloonIcon} />
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

export default About;
